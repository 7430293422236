export default {
  FETCH_ALL_STORE: "FETCH_ALL_STORE",

  FETCH_STORE_LIST: "FETCH_STORE_LIST",
  ADD_STORE: "ADD_STORE",
  FETCH_STORE: "FETCH_STORE",

  FETCH_CATEGORY_LIST: "FETCH_CATEGORY_LIST",
  ADD_CATEGORY: "ADD_CATEGORY",
};
